import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/sections/HomePage/HowItWorks/HowItWorks.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/TitleCard/TitleCard.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/HomePage/CreatedByNiafix/CreatedByNiafix.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/HomePage/Header/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/HomePage/Header/Navbar/Navbar.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/HomePage/ReasonForUse/ReasonForUse.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/Footer/Footer.module.scss");
